.home{
    display: grid;
    grid-template-columns: 300px 1fr;
    min-height: 100vh;
}

.image{
    margin-top: 45px;
    z-index: 1;
}

.image img{
    width: 300px;
    border-radius: 50%;
}

.details{
    text-align: center;
    z-index: 1;
}

.details .details-title{
    font-size: 2.5rem;
    font-weight: 800;
    font-family: "Poppins",sans-serif;
    letter-spacing: 8px;
    color: white;
}

.details  .details-pragraf {
    /* width: 650px; */
    margin: auto;
    color: white;
    letter-spacing: 2px;
    font-size: 1.5rem;
}

.social-links{
    display: flex;
    justify-content: center;
    /* margin: auto;
    margin-top: 50px;
    text-align: center;
    padding: 5px 39%; */
}

.social-links li{
    list-style: none;
    margin: 15px;
}

.social-links li a{
    color: #4a018f;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    color: white;
    background-color: rgb(5, 116, 116);
    border: 2px solid transparent;
}

.social-links li a:hover{
    border: 2px solid black;
}



.social-links i{
    font-size: 2.0rem;
}

.section.right{
    position: relative; 
    /* min-height:100vh; */
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    background-image: url(../../assets/img/react.jpg);
    background-size: cover;
}
.section.right::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(185, 185, 185,.55);
}

.section:nth-child(1){
    align-items:flex-start;
    justify-content: flex-start;
    padding-top: 150px;
    border-right: 1px solid rgba(255, 255, 255, 0.329);
}

/* Küçük ekranlarda (max-width: 768px) navbar üstte olacak */
@media (max-width: 768px) {
    .home {
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        height: auto;
    }
    .section.right{
        flex-direction: column;
    }
}
