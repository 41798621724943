
.sub-section{
    margin-top: 1rem;
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 40px 60px;
    box-sizing: border-box;
    justify-content: center;
}


.img-project{
    height: 360px; 
    background:#fff;
    position: relative;
    display:flex;
    align-items: flex-end;
    box-shadow: 0px 7px 10px rgba(0,0,0,0.5);
    transition:.5s ease-in-out;
    border-radius:30px;
    overflow: hidden;
}

.img-project:hover{
    transform: translateY(-10px);
}

.img-project img {
      width: 100%;
      height: 100%;
      /* object-fit: contain; */
      object-fit: scale-down;
      position: absolute;
}


.img-project:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display:block;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(89, 255, 246, .5),rgba(150,201,61,1));
    z-index: 2;
    transition: 0.5s all;
    opacity: 0;
}

.img-project:hover:before{
    opacity: 1;
}

.img-project .project-info{
    position: relative;
    padding: 5px;
    z-index: 3;
    color: black;
    opacity: 0;
    transform: translateY(30px);
    transition:0.5s all;
}

.img-project:hover .project-info{
    opacity: 1;
    transform: translateY(-10px);
    font-family: 'Dancing Script', cursive;
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    
}

.img-project .project-info h1{
    margin: 0;
}

.img-project .project-info .btn{
    text-decoration: none;
    padding:.5rem 1rem;
    background: #fff;
    color: #000;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: 0.4s ease-in-out;
    border: 3px solid white;
    border-radius: 4px;
    position: relative;
    z-index: 1;
}

.note {
  margin: 1.5rem;
  padding: 15px;
  background-color: #fffbcc; /* Hafif sarı bir arka plan rengi */
  border: 2px solid #f4d03f; /* Daha parlak bir sarı kenarlık */
  border-radius: 8px; /* Köşeleri yumuşatmak için */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Hafif gölge efekti */
  font-size: 16px; /* Yazı boyutunu artırma */
  font-weight: 500; /* Yazıyı daha vurgulu hale getirme */
  color: #333; /* Kolay okunabilir koyu bir yazı rengi */
  text-align: center; /* Metni ortalamak için */
}

.note p {
  margin: 0;
  line-height: 1.6; /* Satır yüksekliğini artırma */
}

/* Küçük ekranlarda (max-width: 768px) */
@media (max-width: 1024px) {
    .sub-section {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (max-width: 768px) {
    .sub-section {
        grid-template-columns: repeat(1, 1fr);
    }
}