.about-me{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    box-sizing: border-box;
}


.about-me div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 20px;
    margin: 0px;
}

.about-container{
    padding: 10px 50px;
}

.title p{
    font-weight: 600;
    letter-spacing: 2px;
    color: rgb(173, 173, 173);
    margin: 5px;
}

.title-value p{
    font-weight: 800;
    letter-spacing: 3px;
    /* color: rgb(228, 228, 228); */
    color: black;
    text-align: left;
    margin: 5px;
}

.name{
    font-family: 800;
    letter-spacing: 3px;
    color: coral;
}

.about-title{
    font-size: 2rem;
    margin-left: -40px;
}

.container-top{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    padding-top: 10px;
    
}

.container-top-img{
    border-radius: 500px;
    overflow: hidden;
}

.about-image{
    width: 200px;
}

.details{
    color: #B4B4B4;
    
}

.details p {
    color: inherit;
    font-size: 1rem;
}

.about-container{
    box-sizing: border-box;
}
span.skills{
    color: red;
    font-weight: bold;
}

/* Küçük ekranlarda (max-width: 768px) */
@media (max-width: 768px) {
    .container-top-img {
        display: none;
    }

}
