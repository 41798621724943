.section{
    display: grid;
    grid-template-columns: repeat(1,2fr);
    box-sizing: border-box;
}
.section-quotes{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    /* justify-content: center; */
    align-items: center;
    padding: 0 150px;
}
.section-quotes img {
    width:100px;
}
.section-contact{
    display: grid;
    grid-template-columns: repeat(2,1fr);
}
.contacts{
    background-color:#72F2EB;
    padding-top:30px;
    height:150px;
    border-radius: 15px;
    margin: 30px;
}
.whatsapp a{
    color: black;
}

.contacts:hover{
    background-color:white;
}

.contact-link {
  text-decoration: none;
  color: inherit;
  transition: color 0.3s ease;
}

.contact-link:hover {
  color: #007bff;
}

/* Küçük ekranlarda (max-width: 1024px) */
@media (max-width: 1024px) {
    .section-contact {
        grid-template-columns: repeat(1, 1fr);
    }
}
